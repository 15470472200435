import React, { Fragment } from 'react';
import options from '../../options';

import { constants } from '../../models';

const { login, logout } = constants;

export default store => next => (action) => {
    if (events[action.type]) {
        // store.dispatch(push(events[action.type]));
    }
    if (action && action.payload && typeof action.payload === 'object') {
        const { status } = action.payload;
        switch (status) {
            case 401: {
                if (!(location.pathname === ('/'))
                    && !(location.pathname.includes('/user-reset-password'))
                    && !(location.pathname.includes('/user-activate'))
                    && !(location.pathname.includes('/representation'))
                ) {
                    // window.location.replace('/');
                }
                break;
            }
            default: {
                break;
            }
        }
    }
    next(action);
};

const events = {
    [login.success]: '/determinants',
    [logout.toString()]: '/'
};
