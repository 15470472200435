import React, { Component, Fragment, useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import Icon, { DiffOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { connect, useDispatch, useSelector } from 'react-redux';

import { Link, withRouter, useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { logout } from '../../models/account/actions';
import { getUserName, getUser } from '../../models/account/selectors';
import { getDeterminantTypes } from '../../models/determinant-types/actions';
import { getRegions } from '../../models/regions/actions';
import { getCurDeterminantTypes, CurDeterminantTypesLoading } from '../../models/determinant-types/selectors';

import cn, { useClassName } from '../../utils/cn';
import LanguageSwitch from '../language-switch';

import SampleLogIcon from '../../../public/images/sidebar/samplelog.svg';
import CalculatorIcon from '../../../public/images/sidebar/calculator.svg';
import DeterminantIcon from '../../../public/images/sidebar/determinant.svg';
import MapIcon from '../../../public/images/sidebar/map.svg';
import PivotIcon from '../../../public/images/sidebar/pivot.svg';
import LogoutIcon from '../../../public/images/sidebar/logout.svg';
import ProfileEditIcon from '../../../public/images/sidebar/profile-edit.svg';
import CatalogIcon from '../../../public/images/sidebar/catalog.svg';
import ProfileIcon from '../../../public/images/sidebar/profile.svg';
import './style.less';
import { DETERMINANTS } from '../../constants/determinats';

import keycloak from '../../utils/keycloak';

const { SubMenu } = Menu;

export default ({}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation('vertical menu');
    const cn = useClassName('menu');
    const match = useRouteMatch();

    const username = useSelector(state => getUserName(state));
    const user = useSelector(state => getUser(state));
    const loading = useSelector(state => CurDeterminantTypesLoading(state));
    const determinants = useSelector(state => getCurDeterminantTypes(state));

    const [currentActiveRows, setCurrentActiveRows] = useState([]);
    const [currentActiveSubmenu, setCurrentActiveSubmenu] = useState([]);
    
    useEffect(() => {
        if (history?.location?.pathname?.includes('/determinants')) {
            setCurrentActiveRows(['determinants']);
            setCurrentActiveSubmenu(['user-profile']);
        }
        if (history?.location?.pathname?.includes('/calculators')) {
            setCurrentActiveRows(['calculators']);
            setCurrentActiveSubmenu(['user-profile']);
        }
        if (
            history?.location?.pathname?.includes('/field-registry')
            || history?.location?.pathname?.includes('/representation')
        ) {
            setCurrentActiveRows(['field-registry']);
            setCurrentActiveSubmenu(['user-profile']);
        }
        if (history?.location?.pathname?.includes('/report-registry')) {
            setCurrentActiveRows(['report-registry']);
            setCurrentActiveSubmenu(['user-profile']);
        }
        if (history?.location?.pathname?.includes('/profile')) {
            setCurrentActiveRows(['profile']);
            setCurrentActiveSubmenu(['user-profile']);
        }

        if (history?.location?.pathname?.includes('/determinant/')) {
            setCurrentActiveRows([`determinant/${match?.params?.id}`]);
            setCurrentActiveSubmenu(['determinant']);
        }

        if (history?.location?.pathname?.includes('/calculator/6')) {
            setCurrentActiveRows(['calculator-norm']);
            setCurrentActiveSubmenu(['calculator']);
        }
        if (history?.location?.pathname?.includes('/calculator-fluid')) {
            setCurrentActiveRows(['calculator-fluid']);
            setCurrentActiveSubmenu(['calculator']);
        }
        if (history?.location?.pathname?.includes('/calculator-fed')) {
            setCurrentActiveRows(['calculator-fed']);
            setCurrentActiveSubmenu(['calculator']);
        }
        if (history?.location?.pathname?.includes('/calculator/7')) {
            setCurrentActiveRows(['calculator-agro']);
            setCurrentActiveSubmenu(['calculator']);
        }
        if (history?.location?.pathname?.includes('/calculator-myco')) {
            setCurrentActiveRows(['calculator-myco']);
            setCurrentActiveSubmenu(['calculator']);
        }
        if (history?.location?.pathname?.includes('/calculator/11')) {
            setCurrentActiveRows(['calculator-nutr']);
            setCurrentActiveSubmenu(['calculator']);
        }
        if (history?.location?.pathname?.includes('/calculator-phyto')) {
            setCurrentActiveRows(['calculator-phyto']);
            setCurrentActiveSubmenu(['calculator']);
        }

        if (
            history?.location?.pathname?.includes('/fertilizers')
            || history?.location?.pathname?.includes('/fertilizer/')
        ) {
            setCurrentActiveRows(['fertilizers']);
            setCurrentActiveSubmenu(['catalog']);
        }
        if (
            history?.location?.pathname?.includes('/diseases')
            || history?.location?.pathname?.includes('/disease/')
        ) {
            setCurrentActiveRows(['diseases']);
            setCurrentActiveSubmenu(['catalog']);
        }
        if (
            history?.location?.pathname?.includes('/harmful-objects')
            || history?.location?.pathname?.includes('/harmful-object/')
        ) {
            setCurrentActiveRows(['harmful-objects']);
            setCurrentActiveSubmenu(['catalog']);
        }
        if (
            history?.location?.pathname?.includes('/pesticides')
            || history?.location?.pathname?.includes('/pesticide/')
        ) {
            setCurrentActiveRows(['pesticides']);
            setCurrentActiveSubmenu(['catalog']);
        }

        if (
            history?.location?.pathname?.includes('/catalog-info')
        ) {
            setCurrentActiveRows([]);
            setCurrentActiveSubmenu(['catalog']);
        }
        if (
            history?.location?.pathname?.includes('/calculators-info')
        ) {
            setCurrentActiveRows([]);
            setCurrentActiveSubmenu(['calculator']);
        }
        if (
            history?.location?.pathname?.includes('/determinants-info')
        ) {
            setCurrentActiveRows([]);
            setCurrentActiveSubmenu(['determinant']);
        }
    }, [history?.location?.pathname]);
    

    useEffect(() => {
        dispatch(getRegions('?page_size=100'));
        dispatch(getDeterminantTypes('?filter={"is_committed":[true]}'));
    }, []);

    const onLogout = () => {
        keycloak?.logout();
    };
    
    // if (loading) return <div />;

    const submenuKeys = [
        'user-profile', 'determinant',
        'calculator', 'catalog'
    ];

    const getLinkToRegs = () => {
        const lastId = localStorage.getItem('teleagronom-last-opened-map');
        return (
            <Link to={`/field-registry/${lastId}`}>
                <Icon component={SampleLogIcon} />
                {t('field registry')}
            </Link>
        );
    }

    return (
        <div className={cn()}>
            <Menu
                style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
                onOpenChange={openKeys => setCurrentActiveSubmenu(openKeys)}
                onSelect={menuItem => {
                    if (submenuKeys?.includes(menuItem?.key)) {
                        setCurrentActiveSubmenu([menuItem?.key]);
                    } else {
                        setCurrentActiveRows([menuItem?.key]);
                    }
                }}
                openKeys={currentActiveSubmenu}
                selectedKeys={currentActiveRows}
                mode="inline">
                <SubMenu
                    id="m_profile"
                    key="user-profile"
                    title={(
                        <span>
                            <Icon component={ProfileIcon} />
                            <span>{keycloak?.idTokenParsed?.preferred_username}</span>
                        </span>
                    )}>
                    <Menu.Item key="determinants" id="p_my_determinants">
                        <Link to="/determinants">
                            <Icon component={DeterminantIcon} />
                            {t('my determinant')}
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="calculators" id="p_my_calculators">
                        <Link to="/calculators">
                            <Icon component={CalculatorIcon} />
                            {t('my calculate')}
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="registryies" id="p_my_registryies">
                        <Link to="/mycological-registries">
                            <Icon component={CalculatorIcon} />
                            {t('mycological registryies')}
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="fedreg" id="p_fed_registry">
                        <Link to="/fed-registries">
                            <Icon component={CalculatorIcon} />
                            {t('fed registryies')}
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="fsmreg" id="p_fsm_registry">
                        <Link to="/fsm-registries">
                            <Icon component={CalculatorIcon} />
                            {t('fsm registryies')}
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="samples" id="p_my_samples">
                        <Link to="/sample-log">
                            <Icon component={SampleLogIcon} />
                            {t('sample log')}
                        </Link>
                    </Menu.Item>
                    {/* <Menu.Item key="recommendations" id="p_my_recommendations">
                        <Link to="/recommendation-registries">
                            <Icon component={SampleLogIcon} />
                            {t('recommendation registries')}
                        </Link>
                    </Menu.Item> */}
                    <Menu.Item key="general_protocol" id="general_protocol">
                        <Link to="/general-protocol">
                            <Icon component={SampleLogIcon} />
                            {t('general protocol')}
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="field-registry" id="field_registry">
                        {getLinkToRegs()}
                    </Menu.Item>
                    <Menu.Item key="report-registry" id="report_registry">
                        <Link to="/report-registry">
                            <Icon component={SampleLogIcon} />
                            {t('report registry')}
                        </Link>
                    </Menu.Item>
                    {/* <Menu.Item key="meteodata" id="meteodata">
                        <Link to="/meteodata">
                            <Icon component={SampleLogIcon} />
                            {t('meteodata')}
                        </Link>
                    </Menu.Item> */}
                    <Menu.Item key="profile" id="p_my_profile">
                        <Link to="/profile">
                            <Icon component={ProfileEditIcon} />
                            {t('profile edit')}
                        </Link>
                    </Menu.Item>
                </SubMenu>
                <SubMenu
                    id="m_determinats"
                    onTitleClick={() => history.push('/determinants-info')}
                    key="determinant"
                    title={(
                        <span>
                            <Icon component={DeterminantIcon} />
                            <span>{t('determinant')}</span>
                        </span>
                    )}>
                    {determinants.map(item => (
                        <Menu.Item key={`determinant/${item.id}`} id={`det_${item.code}`}>
                            <Link to={`/determinant/${item.id}`}>
                                {t(DETERMINANTS[item.name])}
                            </Link>
                        </Menu.Item>
                    ))}
                </SubMenu>
                <SubMenu
                    id="m_calculators"
                    onTitleClick={() => history.push('/calculators-info')}
                    key="calculator"
                    title={(
                        <span>
                            <Icon component={CalculatorIcon} />
                            <span>{t('calculate')}</span>
                        </span>
                    )}>
                    <Menu.Item key="calculator-norm" id="calc_norm">
                        <Link to="/calculator/6">
                            {t('norm calculate')}
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="calculator-fluid" id="calc_fluid">
                        <Link to="/calculator-fluid">
                            {t('fluid calculate')}
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="calculator-fed" id="calc_fed">
                        <Link to="/calculator-fed">
                            {t('fed calculate')}
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="calculator-agro" id="calc_agro">
                        <Link to="/calculator/7">
                            {t('agro calculate')}
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="calculator-myco" id="calc_myco">
                        <Link to="/calculator-myco">
                            {t('myco calculate')}
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="calculator-nutr" id="calc_nutrition">
                        <Link to="/calculator/11">
                            {t('nutrition calculate')}
                        </Link>
                    </Menu.Item>

                    <Menu.Item key="calculator-phyto" id="calc_phyto">
                        <Link to="/calculator-phyto">
                            {t('phyto calculate')}
                        </Link>
                    </Menu.Item>
                </SubMenu>
                <SubMenu
                    id="m_catalogs"
                    onTitleClick={() => history.push('/catalog-info')}
                    key="catalog"
                    title={(
                        <span>
                            <Icon component={CatalogIcon} />
                            <span>{t('catalog')}</span>
                        </span>
                    )}>
                    <Menu.Item key="fertilizers" id="c_fertilizers">
                        <Link to="/fertilizers">
                            {t('fertilizers')}
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="diseases" id="c_diseases">
                        <Link to="/diseases">
                            {t('diseases')}
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="harmful-objects" id="c_harmful">
                        <Link to="/harmful-objects">
                            {t('harmful')}
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="pesticides" id="c_pesticides">
                        <Link to="/pesticides">
                            {t('pesticides')}
                        </Link>
                    </Menu.Item>
                </SubMenu>
                <Menu.Item key="profile-exit" onClick={onLogout} id="p_logout">
                    <Icon component={LogoutIcon} />
                    {t('profile exit')}
                </Menu.Item>
                
                <Menu.Item key="bottom-spacer" disabled style={{ marginTop: 'auto', display: 'hidden' }} />

                <Menu.Item disabled key="change-language" id="change_lang">
                    <LanguageSwitch style={{ width: '100%' }} />
                </Menu.Item>
            </Menu>
        </div>
    );
};