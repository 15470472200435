import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import cn from '../../utils/cn';
import './style.less';
import { withRouter } from 'react-router-dom';

@withRouter
@withTranslation('logo')
@cn('logo')
export default class Logo extends Component {
    render() {
        const { cn } = this;
        const { t, history } = this.props;

        return (
            <div className={cn()} style={{ width: '100%' }} onClick={() => { window.location.replace('/') }}>
                <img className={cn('image')} style={{ margin: 'auto' }} src="/images/favicon_agrodoctor.svg" alt="logo" />
                <span className={cn('text')}>
                    Интеллектуальная система принятия решений
                    {/* {t('tele')}<b>{t('agronom')}</b> */}
                </span>
            </div>
        );
    }
}