import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Form, Layout, Select, Spin, Upload, Tooltip, Modal, Input, InputNumber, Row, Col, notification
} from 'antd';
import {
    CopyOutlined,
    DeleteOutlined,
    DownloadOutlined, ExclamationCircleOutlined,
    ExportOutlined,
    PlusCircleOutlined, QuestionCircleOutlined, SettingOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Button, PanelLayout, Title } from '../../components';
import PanelHeader from '../../components/panel-layout/panel-header';
import PanelContent from '../../components/panel-layout/panel-content';
import { useClassName } from '../../utils/cn';
import SampleTable from './components/sample-table';

import './style.less';
import {
    getSamplesList,
    createSampleRow,
    deleteSampleRow,
    uploadSampleFile, getSampleById, editSampleRow, manageSampleRowPublication
} from '../../models/sample-log/actions';
import {
    curDeleteCount,
    curNewRow,
    curSampleRow,
    curSamplesList, curUpdatedRow,
    curUploadStatus
} from '../../models/sample-log/selectors';
import SampleModal from './components/sample-modal';
import { curMycoFields } from '../../models/mycological-registry/selectors';
import { makeRequest, makeResponse } from './components/utils';
import UploadStatus from './components/upload-status';
import { getUser } from '../../models/account/selectors';
import ExcelExport from './components/excel-export';
import NewUserModal from './components/new-user-modal';
import DefaultSettings from './components/default-settings';

const fullscreenStyles = {
    backgroundColor: 'white',
    width: '100vw',
    height: '100vh',
    position: 'absolute',
    left: '0',
    top: '0',
    zIndex: '9999999999'
};

const columnsDefaultSelect = [
    'productivity_zone', 'zone_area', 'owner', 'public',
    'vega_key', 'field_num', 'point', 'date', 'analysis_date',
    'K', 'A', 'method', 'ph_water', 'electrical_conductivity', 'organic_substances',
    'NH4', 'K20', 'Na', 'Mg', 'Ca', 'Cl', 'SO4', 'S', 'N',
    'NO3', 'PO4', 'P2O5', 'comment', 'num', 'ph_salt'
];

const { confirm } = Modal;

const SampleLog = () => {
    const [numsForm] = Form.useForm();
    const [defaultsForm] = Form.useForm();
    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const cn = useClassName('calculators');
    const { t } = useTranslation('sample log');

    const currentDate = new Date();

    const samplesList = useSelector(state => curSamplesList(state));
    const currentSampleRow = useSelector(state => curSampleRow(state));
    const fields = useSelector(state => curMycoFields(state));
    const uploadStatus = useSelector(state => curUploadStatus(state));
    const newCreatedRow = useSelector(state => curNewRow(state));
    const deletedRow = useSelector(state => curDeleteCount(state));
    const updatedRow = useSelector(state => curUpdatedRow(state));
    const currentUser = useSelector(state => getUser(state));

    const [showModal, setShowModal] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [currentSample, setCurrentSample] = useState({});
    const [currentUpload, setCurrentUpload] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isReportGenerating, setIsReportGenerating] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [columnOptions, setColumnOptions] = useState([]);
    const [activeColumns, setActiveColumns] = useState([]);
    const [filteredSel, setFilteredSel] = useState([]);
    const [defaultModalOpen, setDefaultModalOpen] = useState(false);
    const [isNewUserModalOpen, setIsNewUserModalOpen] = useState(false);
    const [fullUserList, setFullUserList] = useState([]);
    const [publishDisabled, setPublishDisabled] = useState(true);
    const [unpublishDisabled, setUnpublishDisabled] = useState(true);
    const [fullSampleList, setFullSampleList] = useState([]);
    const [samplePage, setSamplePage] = useState(1);
    const [filteredDataSet, setFilteredDataSet] = useState([]);
    const [copyingRow, setCopyingRow] = useState({});
    const [numberOfCopies, setNumberOfCopies] = useState([]);
    const [numConfirmOpen, setNumConfirmOpen] = useState(false);
    const [newlyCreatedRows, setNewlyCreatedRows] = useState([]);
    const [newlyCreatedStorage, setNewlyCreatedStorage] = useState([]);

    useEffect(() => {
        dispatch(getSamplesList({ page: samplePage }));
    }, [samplePage]);

    useEffect(() => {
        const dataPortion = samplesList?.results;
        if (dataPortion?.length > 0) {
            //
            const tempStorage = [];
            dataPortion?.forEach(dataItem => {
                if (newlyCreatedRows?.includes(dataItem?.num)) {
                    tempStorage.push(dataItem);
                }
            })
            setNewlyCreatedStorage(tempStorage.sort((a, b) => a.num - b.num));
            //
            const existingItem = dataPortion[0];
            const finderItems = fullSampleList?.filter(x => x?.id === existingItem?.id);
            if (finderItems?.length > 0) {
                setFullSampleList([...dataPortion].filter(x => !newlyCreatedRows?.includes(x?.num)));
            } else {
                setFullSampleList([...fullSampleList, ...dataPortion].filter(x => !newlyCreatedRows?.includes(x?.num)));
            }
        }
        const nextUrl = samplesList?.next;
        if (nextUrl?.length > 0) {
            setSamplePage(samplePage + 1);
        }
    }, [samplesList]);

    useEffect(() => {
        const selectedRowsActive = selectedRows?.filter(x => !x.deleted);
        setFilteredSel(selectedRowsActive);

        if (selectedRows?.length > 0) {
            let allNotPublic = true;
            let allPublic = true;

            selectedRows?.forEach(rowId => {
                if (fullSampleList?.filter(samplItem => samplItem?.id === rowId)[0] && fullSampleList?.filter(samplItem => samplItem?.id === rowId)[0].public) {
                    allNotPublic = false;
                }
                if (fullSampleList?.filter(samplItem => samplItem?.id === rowId)[0] && !fullSampleList?.filter(samplItem => samplItem?.id === rowId)[0].public) {
                    allPublic = false;
                }
            });

            allPublic && setUnpublishDisabled(false) && setPublishDisabled(true);
            allNotPublic && setPublishDisabled(false) && setUnpublishDisabled(true);
        } else {
            setPublishDisabled(true);
            setUnpublishDisabled(true);
        }
    }, [selectedRows]);


    useEffect(() => {
        if (activeColumns.length < 1 && columnOptions?.length > 0) {
            setActiveColumns(columnOptions);
        }
    }, [columnOptions]);

    useEffect(() => {
        if (deletedRow?.isLoading) {
            setIsLoading(deletedRow.isLoading);
        } else {
            setTimeout(() => {
                dispatch(getSamplesList({ page: 1 }));
                setSamplePage(1);
                setIsLoading(false);
            }, 3000);
        }
    }, [deletedRow]);

    useEffect(() => {
        if (newCreatedRow?.status === 400) {
            notification.open({
                message: 'Ошибка создания строки!',
                description: 'Строка с этим номером пробы уже существует.',
            });
        }
        if (newCreatedRow?.id || updatedRow?.id) {
            dispatch(getSamplesList({ page: 1 }));
            setSamplePage(1);
        }
    }, [updatedRow, newCreatedRow]);

    useEffect(() => {
        if (currentUpload && currentUpload.code) {
            setTimeout(() => setCurrentUpload({}), 4000);
        }
    }, [currentUpload]);

    useEffect(() => {
        if (typeof uploadStatus === 'object' && typeof uploadStatus.then === 'function') {
            uploadStatus.then(x => {
                setCurrentUpload({
                    code: 400,
                    error: JSON.parse(x).error
                });
            });
        } else if (uploadStatus?.id) {
            dispatch(editSampleRow({
                sampleRowId: uploadStatus?.id,
                body: {
                    from_file: true
                }
            }))
            setCurrentUpload({
                code: 200,
                error: t('successful status report')
            });
            setSamplePage(1);
            dispatch(getSamplesList({ page: 1 }));
        }
    }, [uploadStatus]);

    useEffect(() => {
        if (currentSampleRow?.id) {
            setCurrentSample(currentSampleRow);
            const responseValues = makeResponse(currentSampleRow);
            form.setFieldsValue(responseValues);
            if (isFullScreen) {
                setIsFullScreen(false);
            }
            setShowModal(true);
        }
    }, [currentSampleRow]);

    const handleAddRow = () => {
        form?.resetFields();
        setCurrentSample({});
        setShowModal(true);
    };

    const handleRowClick = (sampleRowId) => {
        dispatch(getSampleById({
            sampleRowId: sampleRowId
        }));
    };

    const handleCreateRow = () => {
        const formValues = form.getFieldsValue();
        const requestValues = makeRequest(formValues);
        if (currentSample?.id) {
            dispatch(editSampleRow({
                body: requestValues,
                sampleRowId: currentSample?.id
            }));
        } else {
            dispatch(createSampleRow(requestValues));
        }
        setShowModal(false);
    };

    const handleDeleteRow = () => {
        filteredSel?.length > 0 && filteredSel.forEach((rowId) => {
            setTimeout(() => dispatch(deleteSampleRow({ rowId: rowId })), 500);
        });
    };

    const onFormChange = (value) => {
        const updatedField = Object.keys(value)[0];
        const updatedValue = value[updatedField];

        const fieldsListArr = [];
        const fldKeysArr = Object.keys(fields);

        fldKeysArr.forEach(key => {
            if (key !== 'next') {
                fieldsListArr.push(fields[key]);
            }
        });

        switch (updatedField) {
            case 'dilution_factor':
                if (currentSample?.id) {
                    form?.submit();
                }
                break;
            case 'dist_water_volume':
                if (currentSample?.id) {
                    form?.submit();
                }
                break;
            case 'air_weigth':
                if (currentSample?.id) {
                    form?.submit();
                }
                break;
            case 'field_num':
                const selectedNum = fieldsListArr?.filter(x => x.field_num === updatedValue)[0];
                form?.setFieldsValue({
                    vega_key: selectedNum?.vega_key
                });
                break;
            case 'vega_key':
                const selectedVega = fieldsListArr?.filter(x => x.vega_key === updatedValue)[0];
                form?.setFieldsValue({
                    field_num: selectedVega?.field_num
                });
                break;
            default:
                break;
        }
    };

    const uploadProps = {
        name: 'file',
        customRequest: (reqParams) => {
            const { file } = reqParams;
            return dispatch(uploadSampleFile({
                uploadableFile: file,
                type: 'formdata'
            }));
        }
    };

    const handleGenerateReport = () => {
        setIsReportGenerating(true);

        fetch('/api/v1/agrochemical_analysis/protocol/download_protocol/', {
            method: 'POST',
            headers: {
                Authorization: `JWT ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                analysis_ids: [...filteredSel]
            })
        }).then((resp) => {
            resp.blob().then((blb) => {
                const url = window.URL.createObjectURL(blb);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `[${currentDate.getDate()}.${currentDate.getMonth() + 1}.${ currentDate.getFullYear()}]_SampleAnalysis.docx`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
                setIsReportGenerating(false);
            });
        });
    };

    const handleColumnUpdate = (valueUpdated) => {
        setActiveColumns(valueUpdated.map(x => {
            if (x?.label?.length > 0 && x?.value?.length > 0) {
                return x;
            }
            return {
                label: columnOptions.filter(y => y.value === x)[0]?.label,
                value: x
            };
        }));
    };

    const handlePublication = (toPublish) => {
        if (selectedRows?.length > 0) {
            setIsLoading(true);
            selectedRows?.forEach(rowId => {
                if (toPublish) {
                    setTimeout(() => {
                        dispatch(manageSampleRowPublication({ rowId: rowId, public: true }));
                    }, 100);
                } else {
                    setTimeout(() => {
                        dispatch(manageSampleRowPublication({ rowId: rowId, public: false }));
                    }, 100);
                }
            });

            setTimeout(() => {
                setSamplePage(1);
                dispatch(getSamplesList({ page: 1 }));
            }, 3000);
            setTimeout(() => {
                setUnpublishDisabled(true);
                setPublishDisabled(true);
                setIsLoading(false);
            }, 3500);
        }
    };

    useEffect(() => {
        if (!numConfirmOpen && copyingRow?.owner && numberOfCopies?.length > 0) {
            setNumConfirmOpen(true);

            setTimeout(() => {
                confirm({
                    title: t('line copy description'),
                    icon: <QuestionCircleOutlined />,
                    content: (
                        <Form form={numsForm}>
                            {numberOfCopies?.map((elmnt, idx) => (
                                <Form.Item
                                    label={`${t('copy num')} ${idx + 1}`}
                                    name={`num-${idx}`}>
                                    <Input />
                                </Form.Item>
                            ))}
                        </Form>
                    ),
                    onOk() {
                        const formVals = numsForm?.getFieldsValue();
                        const numsKeys = Object.keys(formVals);
                        const formValKeys = Object.values(formVals);
                        setNewlyCreatedRows(formValKeys.map(x => parseInt(x, 10)).sort((a, b) => a - b));
                        numsKeys?.forEach((key, idx) => {
                            setTimeout(() => {
                                dispatch(createSampleRow({
                                    num: formVals[key],
                                    A: copyingRow?.A,
                                    K: copyingRow?.K,
                                    air_weigth: copyingRow?.air_weigth,
                                    analysis_date: copyingRow?.analysis_date,
                                    date: copyingRow?.date,
                                    dilution_factor: copyingRow?.dilution_factor,
                                    dist_water_volume: copyingRow?.dist_water_volume,
                                    electrical_conductivity: copyingRow?.electrical_conductivity,
                                    field_num: copyingRow?.field_num,
                                    method: copyingRow?.method,
                                    organic_substances: copyingRow?.organic_substances,
                                    owner: copyingRow?.owner,
                                    ph_salt: copyingRow?.ph_salt,
                                    ph_water: copyingRow?.ph_water,
                                    point: copyingRow?.point,
                                    productivity_zone: copyingRow?.productivity_zone,
                                    vega_key: copyingRow?.vega_key,
                                    zone_area: copyingRow?.zone_area,
                                    from_file: false
                                }));
                            }, 300);
                        });
                        setCopyingRow({});
                        setNumberOfCopies([]);
                        setNumConfirmOpen(false);
                    },
                    onCancel() {
                        setCopyingRow({});
                        setNumberOfCopies([]);
                        setNumConfirmOpen(false);
                    }
                });
            }, 500);
        }
    }, [copyingRow, numberOfCopies]);

    const handleCopy = () => {
        const setCopies = (copiesNum) => {
            setNumberOfCopies(Array(copiesNum).fill(0));
        };

        confirm({
            title: t('line copy title'),
            icon: <QuestionCircleOutlined />,
            content: (
                <>
                    <div style={{ marginBottom: '15px' }}>
                        {t('line copy amount')}
                    </div>

                    <InputNumber
                        onChange={(copiesNum) => setCopies(copiesNum)} />
                </>
            ),
            onOk() {
                const foundRow = samplesList?.results?.filter(x => x?.id === selectedRows[0])[0];
                setCopyingRow(foundRow);
            },
            onCancel() {
                setCopyingRow({});
                setNumberOfCopies([]);
            }
        });
    };

    const createExcelFilename = () => {
        return `SampleLogExcel_[${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}]`;
    };

    return isFullScreen ? (
        <div key="page-sample-log" className="fullscreen-table" style={fullscreenStyles}>
                <div className="buttons-row__top">
                    <Upload {...uploadProps} maxCount={2} showUploadList={false}>
                        <Button>
                            <PlusCircleOutlined />
                            {t('add files')}
                        </Button>
                    </Upload>

                    <div className="column-select">
                        <div style={{ margin: 'auto', textAlign: 'center', marginRight: '20px' }}>
                            {t('column selector header')}
                        </div>
                        <Select
                            maxTagCount="responsive"
                            dropdownStyle={{
                                position: 'relative',
                                zIndex: '999999999999999999999'
                            }}
                            style={{ maxWidth: '150px' }}
                            onChange={handleColumnUpdate}
                            mode="multiple"
                            options={columnOptions}
                            defaultValue={() => {
                                if (activeColumns?.length > 0) {
                                    handleColumnUpdate(activeColumns);
                                    return activeColumns?.map(col => col.value);
                                }

                                return columnsDefaultSelect;
                            }} />
                    </div>

                    <div>
                        <Button
                            className="buttons-row__top__first"
                            type="primary"
                            disabled={filteredSel?.length < 1}
                            onClick={handleGenerateReport}>
                            {isReportGenerating && (
                                <Spin />
                            )}
                            {!isReportGenerating && (
                                <>
                                    <DownloadOutlined
                                        className="buttons-row__top__first__icon" />
                                    {t('generate report')}
                                </>
                            )}
                        </Button>
                        <Button
                            type="secondary"
                            disabled={filteredSel?.length < 1}
                            onClick={handleDeleteRow}>
                            <DeleteOutlined />
                            {t('delete')}
                        </Button>
                    </div>

                    <Button
                        type="secondary"
                        onClick={() => {
                            setIsFullScreen(false);
                            setTimeout(() => {
                                setActiveColumns([...activeColumns]);
                            }, 300);
                        }}>
                        {t('close')}
                    </Button>
                </div>
                <div className="fullscreen-table__content">
                    <SampleTable
                        filteredDataSet={filteredDataSet}
                        setFilteredDataSet={setFilteredDataSet}
                        setUserList={setFullUserList}
                        setIsNewUserModalOpen={setIsNewUserModalOpen}
                        isFullScreen={isFullScreen}
                        selectedRows={selectedRows}
                        selectRows={setSelectedRows}
                        tableData={[...fullSampleList, ...newlyCreatedStorage]}
                        exitFullScreen={() => setIsFullScreen(false)}
                        columnOptions={columnOptions}
                        activeColumnOptions={activeColumns}
                        setColumnOptions={setColumnOptions}
                        clickRow={handleRowClick}
                        addRow={handleAddRow} />
                </div>
            </div>
    ) : (
        <Layout key="page-sample-log" className={cn()}>
            <PanelLayout>
                <PanelHeader>
                    <Title title={t('title')} />
                </PanelHeader>
                <PanelContent>
                    <NewUserModal
                        isOpened={isNewUserModalOpen}
                        setOpened={setIsNewUserModalOpen} />
                    {isLoading && (
                        <Spin spinning={isLoading} />
                    )}
                    {!isLoading && (
                        <div>
                            <div className="buttons-row__top">
                                <div>
                                    <Upload {...uploadProps} maxCount={2} showUploadList={false}>
                                        <Button className="buttons-row__top__first__mr-20">
                                            <PlusCircleOutlined />
                                            {t('add files')}
                                        </Button>
                                    </Upload>
                                </div>
                                <div className="buttons-row__top__right">
                                    <div>
                                        <ExcelExport
                                            filteredDataSet={filteredDataSet}
                                            apiData={fullSampleList}
                                            fileName={createExcelFilename()} />
                                        <Button
                                            className="buttons-row__top__first__mr-20"
                                            type="secondary"
                                            disabled={selectedRows?.length < 1}
                                            onClick={handleGenerateReport}>
                                            {isReportGenerating && (
                                                <Spin />
                                            )}
                                            {!isReportGenerating && (
                                                <>
                                                    <DownloadOutlined
                                                        className="buttons-row__top__first__icon" />
                                                    {t('generate report')}
                                                </>
                                            )}
                                        </Button>
                                        <Button
                                            className="buttons-row__top__first"
                                            type="secondary"
                                            disabled={unpublishDisabled}
                                            onClick={() => handlePublication(false)}>
                                            {t('remove publication')}
                                        </Button>
                                    </div>
                                    <div>
                                        <Tooltip title={t('button description publish')}>
                                            <Button
                                                className="buttons-row__mr-20"
                                                disabled={publishDisabled}
                                                icon={<ExportOutlined />}
                                                onClick={() => handlePublication(true)}
                                                type="secondary" />
                                        </Tooltip>

                                        <Button
                                            danger
                                            type="secondary"
                                            disabled={selectedRows?.length < 1}
                                            icon={<DeleteOutlined />}
                                            onClick={handleDeleteRow} />
                                    </div>
                                </div>
                            </div>
                            <div style={{ marginBottom: '15px' }}>
                                <Button
                                    type="secondary"
                                    onClick={() => setDefaultModalOpen(true)}>
                                    <SettingOutlined />
                                    {t('default modal settings')}
                                </Button>

                                <Form form={defaultsForm}>
                                    <DefaultSettings
                                        setIsOpened={setDefaultModalOpen}
                                        form={defaultsForm}
                                        isOpened={defaultModalOpen} />
                                </Form>
                            </div>

                            <Row justify="middle" style={{ marginBottom: '15px' }} gutter={12}>
                                {selectedRows?.length === 1 && (
                                    <Col span={9}>
                                        <Tooltip title={t('button description copy')}>
                                            <Button
                                                style={{ height: '100%' }}
                                                disabled={selectedRows?.length !== 1}
                                                icon={<CopyOutlined />}
                                                onClick={handleCopy}
                                                type="secondary">
                                                    {t('copy row button text')}
                                            </Button>
                                        </Tooltip>
                                    </Col>
                                )}
                                {selectedRows?.length !== 1 && (
                                    <Col span={15}>
                                        <div className="copy_description_frame">
                                            {t('copy row additional description')}
                                        </div>
                                    </Col>
                                )}
                            </Row>

                            <div style={{ marginBottom: '15px' }}>
                                
                            </div>
                            <div
                                style={{
                                    marginBottom: '15px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between'
                                }}>
                                <a onClick={() => setIsFullScreen(true)}>{t('open fullscreen')}</a>
                                <div className="column-select">
                                    <div style={{ margin: 'auto', marginRight: '15px' }}>
                                        {t('column selector header')}
                                    </div>
                                    <Select
                                        style={{
                                            maxWidth: '150px'
                                        }}
                                        maxTagCount="responsive"
                                        onChange={handleColumnUpdate}
                                        mode="multiple"
                                        options={columnOptions}
                                        defaultValue={() => {
                                            if (activeColumns?.length > 0) {
                                                handleColumnUpdate(activeColumns);
                                                return activeColumns?.map(col => col.value);
                                            }

                                            return columnsDefaultSelect;
                                        }} />
                                </div>
                            </div>
                            <SampleTable
                                filteredDataSet={filteredDataSet}
                                setFilteredDataSet={setFilteredDataSet}
                                setUserList={setFullUserList}
                                setIsNewUserModalOpen={setIsNewUserModalOpen}
                                isFullScreen={isFullScreen}
                                selectedRows={selectedRows}
                                selectRows={setSelectedRows}
                                tableData={[...fullSampleList, ...newlyCreatedStorage]}
                                columnOptions={columnOptions}
                                activeColumnOptions={activeColumns}
                                setColumnOptions={setColumnOptions}
                                clickRow={handleRowClick}
                                addRow={handleAddRow} />
                            <Form
                                form={form}
                                onFinish={handleCreateRow}
                                onValuesChange={onFormChange}>
                                <SampleModal
                                    fullUserList={fullUserList}
                                    currentSample={currentSample}
                                    form={form}
                                    handleOk={() => form?.submit()}
                                    handleCancel={() => {
                                        form.resetFields();
                                        setShowModal(false);
                                    }}
                                    isOpened={showModal} />
                            </Form>
                            {currentUpload?.code && (
                                <UploadStatus
                                    statusCode={currentUpload?.code}
                                    statusText={currentUpload?.error} />
                            )}
                        </div>
                    )}
                </PanelContent>
            </PanelLayout>
        </Layout>
    );
};

export default SampleLog;