import React, {useState, useEffect, useCallback} from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Modal, Row, message, notification, Col, Select, Form, Space, Layout, Tooltip } from 'antd';
import { Title, PanelLayout, SideBar } from '../../components';
import './style.less';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import SectionHeaderPath from '../../components/section-header-path';
import TableControls from './components/table-controls';
import ObjectsTable from './components/objects-table';
import { DeleteOutlined, ExclamationCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import YearModal from './components/year-modal';
import { getDistricts, getObjectByLayer, getSoils, getSoilTypes, updateObjectsPage } from '../../models/mordovia/actions';
import { currDistricts, currentObjectByLayer, currentObjectByLayerCount, currentUpdatedObjects, currSoilTypes } from '../../models/mordovia/selectors';
import { toFixed } from '../../utils/utils';
import moment from 'moment';
import { getRegions } from '../../models/regions/actions';
import { getRegionsCatalog } from '../../models/regions/selectors';
import { getSoilCompositionCatalog } from '../../models/directory-soil-composition/selectors';
import { getCalculatorsSoilComposition } from '../../models/directory-soil-composition/actions';
import { getCalculatorsDegreeOfSoil } from '../../models/directory-degree-of-soil/actions';
import { getCalculatorsYear } from '../../models/directory-year/actions';
import { getDegreeOfSoilCatalog } from '../../models/directory-degree-of-soil/selectors';
import { getYearCatalog } from '../../models/directory-year/selectors';
import { getCulturesCatalog } from '../../models/cultures/selectors';
import { getCultures } from '../../models/cultures/actions';

const { PanelHeader, PanelContent } = PanelLayout;

const { confirm } = Modal;

const FieldRegistry = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation('layer objects');
    const match = useRouteMatch();
    const { params: { id: layerId } } = match;

    const currentCultures = useSelector(state => getCulturesCatalog(state));
    const currentRegions = useSelector(state => getRegionsCatalog(state));
    const currentMechanicals = useSelector(state => getSoilCompositionCatalog(state));
    const currentMoistures = useSelector(state => getDegreeOfSoilCatalog(state));
    const currentActions = useSelector(state => getYearCatalog(state));
    const districts = useSelector(state => currDistricts(state));
    const soils = useSelector(state => currSoilTypes(state));

    useEffect(() => {
        dispatch(getRegions('?page_size=100'));
        dispatch(getCalculatorsSoilComposition(''));
        dispatch(getCalculatorsDegreeOfSoil(''));
        dispatch(getCalculatorsYear(''));
        dispatch(getCultures('?page_size=1000'));
        dispatch(getSoils('?page=1&page_size=15'));
        return () => {
            setAllDistricts([]);
            setTableColumns([]);
            setTableData([]);
        };
    }, []);

    const currentObjectsPage = useSelector(state => currentObjectByLayer(state));
    const currentObjectsCount = useSelector(state => currentObjectByLayerCount(state));

    const [districtsPage, setDistrictsPage] = useState(1);
    const [allDistricts, setAllDistricts] = useState([]);
    const [tableColumns, setTableColumns] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [showGeneralData, setShowGeneralData] = useState(true);
    const [tableYears, setTableYears] = useState([]);
    const [showYearModal, setShowYearModal] = useState(false);
    const [objectsPage, setObjectsPage] = useState(1);
    const [objectsPageSize, setObjectsPageSize] = useState(10);
    const [tableStretchDegree, setTableStretchDegree] = useState(0);

    useEffect(() => {
        dispatch(getDistricts(`?page=${districtsPage}&page_size=500`));
    }, [districtsPage]);
    
    useEffect(() => {
        setAllDistricts([...allDistricts, ...districts.results]);
        if (districts?.next?.length > 0) {
            setDistrictsPage(districtsPage + 1);
        }
      }, [districts]);

    useEffect(() => {
        if (currentObjectsPage) {
            const refactoredData = [];
            const allYears = [];
            currentObjectsPage?.forEach(currentObject => {
                const currentObjectCopy = { ...currentObject };

                currentObject?.year_group?.years?.forEach(currentYear => {
                    if (currentYear?.year && !currentObjectCopy[currentYear?.year]) {
                        currentObjectCopy[currentYear?.year] = {};
                    }

                    currentObjectCopy[currentYear?.year].culture = currentYear?.culture;
                    currentObjectCopy[currentYear?.year].planned_yield = currentYear?.planned_yield;
                    currentObjectCopy[currentYear?.year].depth_of_arable_layer = currentYear?.depth_of_arable_layer;
                    currentObjectCopy[currentYear?.year].fertilizer_action_year = currentYear?.fertilizer_action_year;
                    currentObjectCopy[currentYear?.year].sowing_date = currentYear?.sowing_date;

                    if (currentYear?.year && !allYears?.includes(currentYear?.year)) {
                        allYears.push(currentYear?.year);
                    }
                });

                currentObjectCopy.years = currentObject?.year_group?.years?.map(x => x?.year);

                refactoredData.push(currentObjectCopy);
            });

            setTableData(refactoredData);
            const currentDate = new Date();
            const currentYear = currentDate?.getFullYear();
            if (allYears?.length <= 0) {
                setTableYears([{
                    year: 2024,
                    isOpened: true
                }]);
            } else {
                setTableYears(allYears?.map(x => {
                    const findingYear = tableYears?.filter(x => x?.year?.toString() === x?.toString())?.[0];
                    return {
                        year: x,
                        isOpened: !findingYear?.isOpened ? false : true
                    };
                }))
            }
        }
    }, [currentObjectsPage, currentObjectsCount]);

    useEffect(() => {
        if (layerId && objectsPage && objectsPageSize) {
            dispatch(getObjectByLayer({
                page: objectsPage,
                pageSize: objectsPageSize,
                layerId: layerId
            }));
        }
    }, [
        objectsPage, objectsPageSize,
        layerId
    ]);

    const [isDataEdited, setIsDataEdited] = useState(false);

    const askUserToConfirm = () => {
        confirm({
            title: t('leave page without save'),
            icon: <ExclamationCircleOutlined />,
            content: t('leave page without save content'),
            okText: 'Сохранить',
            onOk() {
                handleSaveChanges();
                setTimeout(() => {
                    setIsDataEdited(false);
                }, 1000);
                return '';
            },
            onCancel(close) {
                close();
                return true;
            },
        });

        return '';
    };

    useEffect(() => {
        if (isDataEdited) {
            window.onbeforeunload = function() {
                askUserToConfirm();
                return '';
            };
            window.onpopstate = e => {
                askUserToConfirm();
                return '';
            }
        } else {
            window.onbeforeunload = () => {
                return true;
            };
            window.onpopstate = e => {
                return true;
            }
        }
    }, [isDataEdited]);

    const handleSave = (row, resultingValue = {}) => {
        setIsDataEdited(true);
        const newData = [...tableData]
        const index = newData.findIndex((item) => {
            return row.id === item.id;
        });
        const item = newData[index];
        const resvalInside = resultingValue.soil_type;
        if (resvalInside) {
            if (!Object.keys(resvalInside)?.includes('value') && !Object.keys(resvalInside)?.includes('is_default')) {
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                    ...resultingValue
                });
            }
        } else {
            newData.splice(index, 1, {
                ...item,
                ...row,
                ...resultingValue
            });
        }
        setTableData(newData);
        const restructuredPage = restructureCurrentPage(newData)
        dispatch(updateObjectsPage(restructuredPage));
    };

    const getDistrictById = (districtId) => {
        return allDistricts?.filter(x => x?.id === districtId)?.[0]?.name;
    };

    const getSoilById = (soilId) => {
        return soils?.filter(x => x?.id === soilId)?.[0]?.name;
    };

    const generalDataSubcolumns = [
        {
            title: t('table header type'),
            dataIndex: ['registry_object', 'object_type'],
            key: 'type',
            editable: false,
            render: val => val ? (<Tooltip placement="bottom" title={t('locked value')}>{t(val)}</Tooltip>) : (<Tooltip placement="bottom" title={t('locked value')}>-</Tooltip>)
        },
        {
            title: t('table header region'),
            dataIndex: ['region', 'name'],
            key: 'region',
            editable: true,
            onCell: record => ({
                record,
                parentKey: 'region',
                editable: true,
                dataIndex: ['region', 'name'],
                title: t('table header region'),
                handleSave: handleSave
            })
        },
        {
            title: t('table header district'),
            dataIndex: 'district',
            key: 'district',
            editable: true,
            onCell: record => ({
                record,
                parentKey: 'district',
                editable: true,
                dataIndex: 'district',
                title: t('table header district'),
                handleSave: handleSave
            })
        },
        {
            title: t('table header owner user'),
            dataIndex: ['owner_user', 'name'],
            key: 'owner_user',
            editable: true,
            onCell: record => ({
                record,
                parentKey: 'owner_user',
                editable: true,
                dataIndex: ['owner_user', 'name'],
                title: t('table header owner user'),
                handleSave: handleSave
            })
        },
        {
            title: t('table header owner'),
            dataIndex: ['user', 'username'],
            key: 'owner',
            render: val => val ? (<Tooltip placement="bottom" title={t('locked value')}>{t(val)}</Tooltip>) : (<Tooltip placement="bottom" title={t('locked value')}>-</Tooltip>),
            editable: false,
        },
        {
            title: t('table header area'),
            dataIndex: 'area',
            key: 'area',
            render: val => val ? toFixed(val, 2) : '-',
            editable: true,
            onCell: record => ({
                record,
                parentKey: 'area',
                editable: true,
                dataIndex: 'area',
                render: val => val ? toFixed(val, 2) : '-',
                title: t('table header area'),
                handleSave: handleSave
            })
        },
        {
            title: t('table header soil type'),
            dataIndex: ['soil_type'],
            key: 'soil_type',
            editable: true,
            onCell: record => ({
                record,
                parentKey: 'soil_type',
                editable: true,
                dataIndex: 'soil_type',
                title: t('table header soil type'),
                handleSave: handleSave
            })
        },
        {
            title: t('table header soil content'),
            dataIndex: ['mechanical_composition'],
            key: 'mechanical_composition',
            editable: true,
            onCell: record => ({
                record,
                parentKey: 'mechanical_composition',
                editable: true,
                dataIndex: ['mechanical_composition'],
                title: t('table header soil content'),
                handleSave: handleSave
            })
        },
        {
            title: t('table header soil moisture'),
            dataIndex: ['degree_of_soil_moisture'],
            key: 'degree_of_soil_moisture',
            editable: true,
            onCell: record => ({
                record,
                parentKey: 'degree_of_soil_moisture',
                editable: true,
                dataIndex: ['degree_of_soil_moisture'],
                title: t('table header soil moisture'),
                handleSave: handleSave
            })
        }
    ].map(x => {
        x.render = (value, record) => {
            if (value?.is_default || value?.value) {
                let possibleValueOne = value?.value;
                if (possibleValueOne?.name) {
                    possibleValueOne = possibleValueOne?.name;
                }
                return value?.is_default === false ? (
                    <span style={{ fontWeight: '800' }}>{possibleValueOne}</span>
                ) : (
                    <Tooltip mouseEnterDelay={1} placement="top" title={'Автозаполняемое значение'}>
                        <span>{Object.keys(possibleValueOne || {})?.includes('value') ? possibleValueOne?.value?.name ? possibleValueOne?.value?.name : possibleValueOne?.value : possibleValueOne}</span>
                    </Tooltip>
                );
            } else {
                let possibleValue = value;
                if (possibleValue?.name) {
                    possibleValue = possibleValue?.name;
                }
                return (<Tooltip mouseEnterDelay={1} placement="top" title={'Автозаполняемое значение'}><span>{Object.keys(possibleValue || {})?.includes('value') ? possibleValue?.value?.name ? possibleValue?.value?.name : possibleValue?.value : possibleValue}</span></Tooltip>);
            }
        }
        return x;
    });

    const handleYearShrink = (inputYear) => {
        setTableYears([...tableYears]?.map(tableYear => {
            if (tableYear?.year === inputYear) {
                return {
                    year: tableYear?.year,
                    isOpened: !tableYear?.isOpened
                };
            }

            return tableYear;
        }))
    };

    const handleYearDeletion = (deletableYear) => {
        setTableData(tableData?.map(dataEntry => {
            dataEntry.years = dataEntry?.years?.filter(yr => yr !== deletableYear);
            dataEntry.year_group.years = dataEntry?.year_group?.years?.filter(yr => yr?.year !== deletableYear);
            delete dataEntry[deletableYear];
            return dataEntry;
        }));
        setTableYears(tableYears?.filter(x => x?.year !== deletableYear));
    };

    useEffect(() => {
        let newStretchDegree = 0;
        let resultingColumns = [
            {
                title: t('table header name'),
                dataIndex: 'field_num',
                key: 'field_num',
                fixed: 'left',
                render: value => (<Tooltip placement="bottom" title={t('locked value')}>{value}</Tooltip>)
            },
        ];

        if (showGeneralData) {
            newStretchDegree += 0.5;
            resultingColumns = [
                ...resultingColumns,
                {
                    title: (
                        <Space direction="horizontal">
                            <div>{t('table header general data')}</div>
                            <a onClick={() => setShowGeneralData(!showGeneralData)}>{t('hide')}</a>
                        </Space>
                    ),
                    editable: true,
                    children: generalDataSubcolumns
                },
            ];
        } else {
            newStretchDegree += 1;

            resultingColumns = [
                ...resultingColumns,
                {
                    title: (
                        <Space direction="horizontal">
                            <div>{t('table header general data')}</div>
                            <a onClick={() => setShowGeneralData(!showGeneralData)}>{t('unhide')}</a>
                        </Space>
                    ),
                    dataIndex: ['registry_object', 'object_type'],
                    key: 'type',
                    editable: false,
                    render: val => val ? (<Tooltip placement="bottom" title={t('locked value')}>{t(val)}</Tooltip>) : (<Tooltip placement="bottom" title={t('locked value')}>-</Tooltip>)
                }
            ];
        }

        if (tableYears?.length > 0) {
            const yearSubgroup = [];
            tableYears?.forEach((currentYearObject, curIdx) => {
                if (currentYearObject?.isOpened) {
                    newStretchDegree += 1;

                    const yearSubcolumns = [
                        {
                            title: t('table header current culture'),
                            dataIndex: ['culture', 'name'],
                            key: 'culture',
                            editable: true,
                            onCell: record => ({
                                record,
                                parentKey: 'culture',
                                editable: true,
                                dataIndex: ['culture', 'name'],
                                title: t('table header current culture'),
                                handleSave: handleSave
                            })
                        },
                        {
                            title: t('table header culture predecessor'),
                            dataIndex: ['preceding_culture', 'name'],
                            key: 'preceding_culture',
                            editable: true,
                            onCell: record => ({
                                record,
                                parentKey: 'preceding_culture',
                                editable: true,
                                dataIndex: ['preceding_culture', 'name'],
                                title: t('table header culture predecessor'),
                                handleSave: handleSave
                            })
                        },
                        {
                            title: t('table header depth'),
                            dataIndex: 'depth_of_arable_layer',
                            key: 'depth_of_arable_layer',
                            editable: true,
                            onCell: record => ({
                                record,
                                parentKey: 'depth_of_arable_layer',
                                editable: true,
                                dataIndex: 'depth_of_arable_layer',
                                title: t('table header depth'),
                                handleSave: handleSave
                            })
                        },
                        {
                            title: t('table header planned yield'),
                            dataIndex: 'planned_yield',
                            key: 'planned_yield',
                            editable: true,
                            onCell: record => ({
                                record,
                                parentKey: 'planned_yield',
                                editable: true,
                                dataIndex: 'planned_yield',
                                title: t('table header planned yield'),
                                handleSave: handleSave
                            })
                        },
                        {
                            title: t('table header sowing date'),
                            dataIndex: 'sowing_date',
                            key: 'sowing_date',
                            render: val => val?.length > 0 ? moment(val).format('DD.MM.YYYY') : '-',
                            editable: true,
                            onCell: record => ({
                                record,
                                parentKey: 'sowing_date',
                                editable: true,
                                dataIndex: 'sowing_date',
                                title: t('table header sowing date'),
                                handleSave: handleSave
                            })
                        },
                        {
                            title: t('table header action year'),
                            dataIndex: ['fertilizer_action_year', 'name'],
                            key: 'fertilizer_action_year',
                            editable: true,
                            onCell: record => ({
                                record,
                                parentKey: 'fertilizer_action_year',
                                editable: true,
                                dataIndex: ['fertilizer_action_year', 'name'],
                                title: t('table header action year'),
                                handleSave: handleSave
                            })
                        }
                    ].map(x => {
                        x.render = (value, record) => {
                            if (value?.is_default || value?.value) {
                                let possibleValueOne = value?.value;
                                if (possibleValueOne?.name) {
                                    possibleValueOne = possibleValueOne?.name;
                                }
                                return value?.is_default === false ? (
                                    <span style={{ fontWeight: '800' }}>{possibleValueOne}</span>
                                ) : (
                                    <Tooltip mouseEnterDelay={1} placement="top" title={'Автозаполняемое значение'}>
                                        <span>{Object.keys(possibleValueOne || {})?.includes('value') ? possibleValueOne?.value?.name ? possibleValueOne?.value?.name : possibleValueOne?.value : possibleValueOne}</span>
                                    </Tooltip>
                                );
                            } else {
                                let possibleValue = value;
                                if (possibleValue?.name) {
                                    possibleValue = possibleValue?.name;
                                }
                                return (<Tooltip mouseEnterDelay={1} placement="top" title={'Автозаполняемое значение'}><span>{Object.keys(possibleValue || {})?.includes('value') ? possibleValue?.value?.name ? possibleValue?.value?.name : possibleValue?.value : possibleValue}</span></Tooltip>);
                            }
                        }
                        return x;
                    });
                    
                    yearSubgroup.push({
                        title: (
                            <Space direction="horizontal">
                                <div><DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => handleYearDeletion(currentYearObject?.year)} /></div>
                                <div>{currentYearObject?.year}</div>
                                <a onClick={() => handleYearShrink(currentYearObject?.year)}>{t('hide')}</a>
                            </Space>
                        ),
                        editable: true,
                        className: (curIdx + 1) % 2 !== 0 ? "grey-row" : null,
                        children: yearSubcolumns?.map(yearSubColumn => {
                            
                            let newDI = [currentYearObject?.year];

                            if (typeof yearSubColumn?.dataIndex === 'string') {
                                newDI.push(yearSubColumn?.dataIndex);
                            } else {
                                yearSubColumn?.dataIndex?.forEach(dI => newDI.push(dI));
                            }

                            return {
                                title: yearSubColumn?.title,
                                dataIndex: newDI,
                                key: `${currentYearObject?.year}-${yearSubColumn?.key}`,
                                className: (curIdx + 1) % 2 !== 0 ? "grey-row" : null,
                                onCell: record => ({
                                    record,
                                    editable: yearSubColumn?.editable,
                                    dataIndex: newDI,
                                    parentKey: yearSubColumn?.key,
                                    title: yearSubColumn?.title,
                                    handleSave: (row, resultingValue = {}) => {
                                        const resKeys = Object.keys(Object.entries(resultingValue)?.[0]?.[1]);
                                        if (['depth_of_arable_layer', 'planned_yield'].includes(resKeys?.[0])) {
                                            if (Object.entries(resultingValue)?.[0]?.[1][resKeys?.[0]] !== undefined) {
                                                setIsDataEdited(true);
                                                const newData = [...tableData]
                                                const index = newData.findIndex((item) => {
                                                    return row.id === item.id;
                                                });
                                                const item = newData[index];

                                                const valsCpy = {...resultingValue};
                                                if (!valsCpy?.sowing_date) {
                                                    delete valsCpy.sowing_date
                                                }
                                        
                                                const resultingInsertable = {
                                                    ...row,
                                                    ...item
                                                };

                                                resultingInsertable.registry_object = row.registry_object;
                                                resultingInsertable.region = row.region;
                                                resultingInsertable.district = row.district;
                                                resultingInsertable.owner_user = row.owner_user;
                                                resultingInsertable.area = row.area;
                                                resultingInsertable.soil_type = row.soil_type;
                                                resultingInsertable.mechanical_composition = row.mechanical_composition;
                                                resultingInsertable.degree_of_soil_moisture = row.degree_of_soil_moisture;

                                                if (!resultingInsertable[currentYearObject?.year]) {
                                                    resultingInsertable[currentYearObject?.year] = {
                                                        culture: {
                                                            name: null,
                                                            id: null
                                                        },
                                                        preceding_culture: {
                                                            name: null,
                                                            id: null
                                                        },
                                                        depth_of_arable_layer: null,
                                                        planned_yield: null,
                                                        sowing_date: null,
                                                        fertilizer_action_year: {
                                                            name: null,
                                                            id: null
                                                        }
                                                    };
                                                }

                                                if (yearSubColumn?.key === 'culture') {
                                                    resultingInsertable[currentYearObject?.year].culture = resultingValue[currentYearObject?.year]?.culture;
                                                } else if (yearSubColumn?.key === 'preceding_culture') {
                                                    resultingInsertable[currentYearObject?.year].preceding_culture = resultingValue[currentYearObject?.year]?.preceding_culture;
                                                } else if (yearSubColumn?.key === 'depth_of_arable_layer') {
                                                    resultingInsertable[currentYearObject?.year].depth_of_arable_layer = resultingValue[currentYearObject?.year]?.depth_of_arable_layer || null;
                                                } else if (yearSubColumn?.key === 'planned_yield') {
                                                    resultingInsertable[currentYearObject?.year].planned_yield = resultingValue[currentYearObject?.year]?.planned_yield || null;
                                                } else if (yearSubColumn?.key === 'sowing_date') {
                                                    let resDate;
                                                    if (typeof resultingValue[currentYearObject?.year]?.sowing_date === 'string') {
                                                        resDate = resultingValue[currentYearObject?.year]?.sowing_date;
                                                    } else if (resultingValue[currentYearObject?.year]?.sowing_date.format) {
                                                        resDate = resultingValue[currentYearObject?.year]?.sowing_date?.format('YYYY-MM-DD');
                                                    }
                                                    resultingInsertable[currentYearObject?.year].sowing_date = resDate;
                                                } else if (yearSubColumn?.key === 'fertilizer_action_year') {
                                                    resultingInsertable[currentYearObject?.year].fertilizer_action_year = resultingValue[currentYearObject?.year]?.fertilizer_action_year;
                                                } else {

                                                }

                                                newData[index] = { ...resultingInsertable };
                                                
                                                setTableData([...newData]);
                                            }
                                        } else {
                                            setIsDataEdited(true);
                                            const newData = [...tableData]
                                            const index = newData.findIndex((item) => {
                                                return row.id === item.id;
                                            });
                                            const item = newData[index];

                                            const valsCpy = {...resultingValue};
                                            if (!valsCpy?.sowing_date) {
                                                delete valsCpy.sowing_date
                                            }
                                            
                                            const resultingInsertable = {
                                                ...row,
                                                ...item
                                            };

                                            resultingInsertable.registry_object = row.registry_object;
                                            resultingInsertable.region = row.region;
                                            resultingInsertable.district = row.district;
                                            resultingInsertable.owner_user = row.owner_user;
                                            resultingInsertable.area = row.area;
                                            resultingInsertable.soil_type = row.soil_type;
                                            resultingInsertable.mechanical_composition = row.mechanical_composition;
                                            resultingInsertable.degree_of_soil_moisture = row.degree_of_soil_moisture;

                                            if (!resultingInsertable[currentYearObject?.year]) {
                                                resultingInsertable[currentYearObject?.year] = {
                                                    culture: {
                                                        name: null,
                                                        id: null
                                                    },
                                                    preceding_culture: {
                                                        name: null,
                                                        id: null
                                                    },
                                                    depth_of_arable_layer: null,
                                                    planned_yield: null,
                                                    sowing_date: null,
                                                    fertilizer_action_year: {
                                                        name: null,
                                                        id: null
                                                    }
                                                };
                                            }

                                            if (yearSubColumn?.key === 'culture') {
                                                resultingInsertable[currentYearObject?.year].culture = resultingValue[currentYearObject?.year]?.culture;
                                            } else if (yearSubColumn?.key === 'preceding_culture') {
                                                resultingInsertable[currentYearObject?.year].preceding_culture = resultingValue[currentYearObject?.year]?.preceding_culture;
                                            } else if (yearSubColumn?.key === 'depth_of_arable_layer') {
                                                resultingInsertable[currentYearObject?.year].depth_of_arable_layer = resultingValue[currentYearObject?.year]?.depth_of_arable_layer || null;
                                            } else if (yearSubColumn?.key === 'planned_yield') {
                                                resultingInsertable[currentYearObject?.year].planned_yield = resultingValue[currentYearObject?.year]?.planned_yield || null;
                                            } else if (yearSubColumn?.key === 'sowing_date') {
                                                let resDate;
                                                if (typeof resultingValue[currentYearObject?.year]?.sowing_date === 'string') {
                                                    resDate = resultingValue[currentYearObject?.year]?.sowing_date;
                                                } else if (resultingValue[currentYearObject?.year]?.sowing_date.format) {
                                                    resDate = resultingValue[currentYearObject?.year]?.sowing_date?.format('YYYY-MM-DD');
                                                }
                                                resultingInsertable[currentYearObject?.year].sowing_date = resDate;
                                            } else if (yearSubColumn?.key === 'fertilizer_action_year') {
                                                resultingInsertable[currentYearObject?.year].fertilizer_action_year = resultingValue[currentYearObject?.year]?.fertilizer_action_year;
                                            }
                                            newData[index] = { ...resultingInsertable };
                                            
                                            setTableData([...newData]);
                                            const restructuredPage = restructureCurrentPage(newData);
                                            dispatch(updateObjectsPage(restructuredPage));
                                        }
                                    }
                                })
                            };
                        })
                    });
                } else {
                    newStretchDegree += 0.5;

                    yearSubgroup.push({
                        title: (
                            <Space direction="horizontal">
                                <div><DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => handleYearDeletion(currentYearObject?.year)} /></div>
                                <div>{currentYearObject?.year}</div>
                                <a onClick={() => handleYearShrink(currentYearObject?.year)}>{t('unhide')}</a>
                            </Space>
                        ),
                        className: (curIdx + 1) % 2 !== 0 ? "grey-row" : null,
                        editable: false,
                        dataIndex: ['culture', 'name'],
                        key: 'culture'
                    });
                }
            });
            resultingColumns = [...resultingColumns, ...yearSubgroup];
        }

        let newStretchDegreeMethod = 0;
        [...resultingColumns, {
            title: (<Tooltip placement="bottom" title={t('add year')}><Button onClick={() => setShowYearModal(true)} style={{ width: '100%', height: '100%' }} type="primary"><PlusCircleOutlined /></Button></Tooltip>),
            width: 100,
            className: "add-year-header-wonderful-requirement-awesome-design",
            key: 'action',
        }].forEach(currCol => {
            if (currCol?.children?.length === 9) {
                newStretchDegreeMethod += 900;
            } else if (currCol?.children?.length === 6) {
                newStretchDegreeMethod += 600;
            } else {
                newStretchDegreeMethod += 300;
            }
        });
        setTableStretchDegree(newStretchDegreeMethod);

        setTableColumns([...resultingColumns, {
            title: (<Tooltip placement="bottom" title={t('add year')}><Button onClick={() => setShowYearModal(true)} style={{ width: '100%', height: '100%' }} type="primary"><PlusCircleOutlined /></Button></Tooltip>),
            width: 100,
            className: "add-year-header-wonderful-requirement-awesome-design",
            key: 'action',
        }]);
    }, [tableYears, showGeneralData, tableData]);
    

    const headerPath = [
        {
            name: t('header path first'),
            path: '/field-registry'
        },
        {
            name: t('header path second'),
            path: '/layer-objects'
        },
    ];

    const [headerBackRoute, setHeaderBackRoute] = useState({});

    const [modalDanger, setModalDanger] = useState(false);

    const addSelectedYear = (newYear) => {
        const tryingToFind = tableYears?.filter(x => x?.year?.toString() === newYear?.toString());
        if (tryingToFind?.length === 0) {
            setTableYears([...tableYears, {
                year: newYear,
                isOpened: true
            }]);
            setModalDanger(false);
            setShowYearModal(false);
        } else {
            setModalDanger(true);
        }
    };

    const handleCancelChanges = () => {
        if (layerId && objectsPage && objectsPageSize) {
            setTableData([]);

            dispatch(getObjectByLayer({
                page: objectsPage,
                pageSize: objectsPageSize,
                layerId: layerId
            }));
        }
    };
    
    const restructureCurrentPage = (possibleData) => {
        let currentDataCopy = [...tableData];

        if (possibleData) {
            currentDataCopy = possibleData;
        }
        const desiredFieldsArray = [];

        currentDataCopy?.forEach(curObject => {
            let newRegion;
            let newMechanical;
            let newMoisture;

            if (curObject?.region?.id && curObject?.region?.id > 0) {
                newRegion = curObject?.region?.id;
            } else {
                const findingRegion = currentRegions?.filter(x => x?.name === curObject?.region?.name)?.[0];
                newRegion = findingRegion?.id;
            }

            if (curObject?.mechanical_composition?.id && curObject?.mechanical_composition?.id > 0) {
                newMechanical = curObject?.mechanical_composition?.id;
            } else {
                const findingMechanical = currentMechanicals?.filter(x => x?.name === curObject?.mechanical_composition?.name)?.[0];
                newMechanical = findingMechanical?.id;
            }

            if (curObject?.degree_of_soil_moisture?.id && curObject?.degree_of_soil_moisture?.id > 0) {
                newMoisture = curObject?.degree_of_soil_moisture?.id;
            } else {
                const findingMoisture = currentMoistures?.filter(x => x?.name === curObject?.degree_of_soil_moisture?.name)?.[0];
                newMoisture = findingMoisture?.id;
            }

            let possibleDistrict = curObject?.district;
            if (possibleDistrict?.value) {
                possibleDistrict = possibleDistrict?.value;
            }
            if (possibleDistrict?.id) {
                possibleDistrict = possibleDistrict?.id;
            }

            let possibleSoil = curObject?.soil_type;
            if (possibleSoil?.value) {
                possibleSoil = possibleSoil?.value;
            }
            if (possibleSoil?.id) {
                possibleSoil = possibleSoil?.id;
            }
            
            const fieldBody = {
                id: curObject?.id,
                area: curObject?.area,
                region: newRegion,
                owner_user: curObject?.owner_user?.id,
                field_num: curObject?.field_num,
                district: possibleDistrict || true,
                oktmo: curObject?.oktmo,
                farmland_type: curObject?.farmland_type,
                soil_type: possibleSoil,
                rural_settlement: curObject?.rural_settlement,
                mechanical_composition: newMechanical,
                degree_of_soil_moisture: newMoisture,
                years: []
            };

            tableYears.forEach(tabYr => {
                const { year: currentYearId } = tabYr;
                const currentYearData = curObject[currentYearId];

                let newCulture;
                if (currentYearData?.culture?.id && currentYearData?.culture?.id > 0) {
                    newCulture = currentYearData?.culture?.id;
                } else {
                    const findingCulture = currentCultures?.filter(x => x?.name === currentYearData?.culture?.name)?.[0];
                    newCulture = findingCulture?.id;
                }
                let newPreceding;
                if (currentYearData?.preceding_culture?.id && currentYearData?.preceding_culture?.id > 0) {
                    newPreceding = currentYearData?.preceding_culture?.id;
                } else {
                    const findingPreceding = currentCultures?.filter(x => x?.name === currentYearData?.preceding_culture?.name)?.[0];
                    newPreceding = findingPreceding?.id;
                }
                let newActionYear;
                if (currentYearData?.fertilizer_action_year?.id && currentYearData?.fertilizer_action_year?.id > 0) {
                    newActionYear = currentYearData?.fertilizer_action_year?.id;
                } else {
                    const findingAction = currentActions?.filter(x => x?.name === currentYearData?.fertilizer_action_year?.name)?.[0];
                    newActionYear = findingAction?.id;
                }

                if (
                    currentYearData?.sowing_date || newCulture || newPreceding || currentYearData?.planned_yield
                    || newActionYear || currentYearData?.soil_sampling_depth || currentYearData?.depth_of_arable_layer
                ) {
                    fieldBody?.years?.push({
                        year: currentYearId,
                        sowing_date: typeof currentYearData?.sowing_date === 'object' ? currentYearData?.sowing_date?.value : currentYearData?.sowing_date,
                        culture: newCulture,
                        preceding_culture: newPreceding,
                        planned_yield: typeof currentYearData?.planned_yield === 'object' ? currentYearData?.planned_yield?.value : currentYearData?.planned_yield,
                        fertilizer_action_year: newActionYear,
                        soil_sampling_depth: typeof currentYearData?.soil_sampling_depth === 'object' ? currentYearData?.soil_sampling_depth?.value : currentYearData?.soil_sampling_depth,
                        depth_of_arable_layer: typeof currentYearData?.depth_of_arable_layer === 'object' ? currentYearData?.depth_of_arable_layer?.value : currentYearData?.depth_of_arable_layer,
                    });
                }
            });

            desiredFieldsArray.push(fieldBody);
        });

        return {
            fields: desiredFieldsArray
        };
    };

    const updateResults = useSelector(state => currentUpdatedObjects(state));

    const [isAfterPagination, setIsAfterPagination] = useState(false);

    useEffect(() => {
        if (updateResults && !isAfterPagination) {
            const refactoredData = [];
            const allYears = [];
            updateResults && updateResults?.forEach && updateResults?.forEach(currentObject => {
                const currentObjectCopy = { ...currentObject };

                currentObject?.year_group?.years?.forEach(currentYear => {
                    if (currentYear?.year && !currentObjectCopy[currentYear?.year]) {
                        currentObjectCopy[currentYear?.year] = {};
                    }

                    currentObjectCopy[currentYear?.year].culture = currentYear?.culture;
                    currentObjectCopy[currentYear?.year].preceding_culture = currentYear?.preceding_culture;
                    currentObjectCopy[currentYear?.year].planned_yield = currentYear?.planned_yield;
                    currentObjectCopy[currentYear?.year].depth_of_arable_layer = currentYear?.depth_of_arable_layer;
                    currentObjectCopy[currentYear?.year].fertilizer_action_year = currentYear?.fertilizer_action_year;
                    currentObjectCopy[currentYear?.year].sowing_date = currentYear?.sowing_date;

                    if (currentYear?.year && !allYears?.includes(currentYear?.year)) {
                        allYears.push(currentYear?.year);
                    }
                });

                currentObjectCopy.years = currentObject?.year_group?.years?.map(x => x?.year);

                refactoredData.push(currentObjectCopy);
            });

            setTableData(refactoredData);
            setTableYears(allYears?.map((x, idx) => {
                return {
                    year: x,
                    isOpened: tableYears?.[idx]?.isOpened
                };
            }))
        } else if (isAfterPagination) {
            const refreshTimeout = setTimeout(() => {
                setIsAfterPagination(false);
                clearTimeout(refreshTimeout);
            }, 800);
        }
    }, [updateResults]);

    const [refresherFlag, setRefresherFlag] = useState(false);

    useEffect(() => {
        if (refresherFlag) {
            history.push(headerBackRoute?.path);
        }
    }, [refresherFlag]);
    
    

    const handleSaveChanges = () => {
        const restructuredPage = restructureCurrentPage();
        dispatch(updateObjectsPage(restructuredPage));
    };
    
    return (
        <Layout key="layer-objects">
            <PanelLayout>
                <PanelHeader>
                    <SectionHeaderPath
                        isConfirmable={isDataEdited}
                        confirmParameters={{
                            title: t('leave page without save'),
                            icon: <ExclamationCircleOutlined />,
                            content: t('leave page without save content'),
                            okText: 'Сохранить',
                            onOk: (close) => {
                                handleSaveChanges();
                                setIsDataEdited(false);
                                setRefresherFlag(true);
                                close();
                            },
                            onCancel: (close) => {
                                close();
                                return true;
                            },
                        }}
                        isIteractive={false}
                        backRoute={headerBackRoute}
                        pathArray={headerPath} />
                </PanelHeader>
                <PanelContent>
                    <TableControls onSave={handleSaveChanges} onCancel={handleCancelChanges} />
                    <ObjectsTable
                        handleSaveChanges={handleSaveChanges}
                        isDataEdited={isDataEdited}
                        setIsDataEdited={setIsDataEdited}
                        districtOptions={allDistricts.map(x => {
                            return {
                                label: x?.name,
                                value: x?.id
                            }
                        })}
                        regionOptions={currentRegions?.map(x => {
                            return {
                                label: x?.name,
                                value: x?.name
                            }
                        })}
                        mechanicalOptions={currentMechanicals?.map(x => {
                            return {
                                label: x?.name,
                                value: x?.name
                            }
                        })}
                        moistureOptions={currentMoistures?.map(x => {
                            return {
                                label: x?.name,
                                value: x?.name
                            }
                        })}
                        actionOptions={currentActions?.map(x => {
                            return {
                                label: x?.name,
                                value: x?.name
                            }
                        })}
                        cultureOptions={currentCultures?.map(x => {
                            return {
                                label: x?.name,
                                value: x?.name
                            }
                        })}
                        setIsAfterPagination={setIsAfterPagination}
                        setTablePage={setObjectsPage}
                        setTablePageSize={setObjectsPageSize}
                        tablePage={objectsPage}
                        tablePageSize={objectsPageSize}
                        tableDataSize={currentObjectsCount}
                        tableStretch={tableStretchDegree}
                        tableColumns={tableColumns}
                        tableData={tableData}
                        setTableData={setTableData} />
                    <YearModal modalDanger={modalDanger} isOpened={showYearModal} handleOk={addSelectedYear} handleCancel={() => {
                        setModalDanger(false);
                        setShowYearModal(false);
                    }} />
                </PanelContent>
            </PanelLayout>
        </Layout>
    );
};

export default FieldRegistry;