import React from 'react';
import moment from 'moment';
import { updateFileDescription } from '../../../models/mycological-registry/actions';

export const makeRequest = (values, registry = {}, dispFunc) => {
    const myco1Keys = Object.keys(values).filter(item => item.includes('mycobiota_type_1/'));
    const myco2Keys = Object.keys(values).filter(item => item.includes('mycobiota_type_2/'));
    const myco3Keys = Object.keys(values).filter(item => item.includes('mycobiota_type_3/'));

    const bacteriaVals = Object.keys(values).filter(item => item.includes('mycobiota_type_0/40'));
    const azotobacterVals = Object.keys(values).filter(item => item.includes('azotobacter/'));

    const photo1 = registry?.pictures1;
    const photo2 = registry?.pictures2;
    const photo3 = registry?.pictures3;

    const photo1Keys = Object.keys(values).filter(item => item.includes('picture_desc/1/'));
    const photo2Keys = Object.keys(values).filter(item => item.includes('picture_desc/2/'));
    const photo3Keys = Object.keys(values).filter(item => item.includes('picture_desc/3/'));

    photo1Keys.forEach(phKey => {
        const phId = parseInt(phKey.split('/')[2], 10);
        const toChange = photo1?.filter(x => x.id === phId)[0];
        toChange.picture_desc = values[phKey];
        dispFunc && dispFunc(updateFileDescription({
            pictureId: toChange.id,
            values: {
                picture_desc: toChange.picture_desc
            }
        }));
    });
    photo2Keys.forEach(phKey => {
        const phId = parseInt(phKey.split('/')[2], 10);
        const toChange = photo2?.filter(x => x.id === phId)[0];
        toChange.picture_desc = values[phKey];
        dispFunc && dispFunc(updateFileDescription({
            pictureId: toChange.id,
            values: {
                picture_desc: toChange.picture_desc
            }
        }));
    });
    photo3Keys.forEach(phKey => {
        const phId = parseInt(phKey.split('/')[2], 10);
        const toChange = photo3?.filter(x => x.id === phId)[0];
        toChange.picture_desc = values[phKey];
        dispFunc && dispFunc(updateFileDescription({
            pictureId: toChange.id,
            values: {
                picture_desc: toChange.picture_desc
            }
        }));
    });

    const bacteriaSteps = bacteriaVals?.length;
    const bacteriaRepetitions = new Array(bacteriaSteps).fill(0);
    bacteriaVals.forEach(bacVal => {
        const stepNumber = parseInt(bacVal?.split('/')[2], 10);
        bacteriaRepetitions[stepNumber] = values[bacVal];
    });

    const azotobacterSteps = azotobacterVals?.length;
    const azotobacterRepetitions = new Array(azotobacterSteps).fill(0);
    azotobacterVals.forEach(bacVal => {
        const stepNumber = parseInt(bacVal?.split('/')[1], 10);
        azotobacterRepetitions[stepNumber] = values[bacVal];
    });

    const mycoOneData = {};
    myco1Keys.forEach(x => {
        const splitKey = x.split('/');
        const mycoItem = parseInt(splitKey[1], 10);
        const mycoStep = parseInt(splitKey[2], 10);
        if (!mycoOneData[mycoItem]) {
            mycoOneData[mycoItem] = {
                repetitions: [],
                mycobiota_type: 1
            };
        }
        if (x?.split('/')[2]) {
            mycoOneData[mycoItem].repetitions[mycoStep] = values[x];
        }
    });

    const mycoTwoData = {};
    myco2Keys.forEach(x => {
        if (!mycoTwoData[x.split('/')[1]]) {
            mycoTwoData[x.split('/')[1]] = {
                repetitions: [],
                mycobiota_type: 2
            };
        }
        if (x?.split('/')[2]) {
            mycoTwoData[x.split('/')[1]].repetitions[parseInt(x?.split('/')[2], 10)] = values[x];
        }
    });

    const mycoThreeData = {};
    myco3Keys.forEach(x => {
        if (!mycoThreeData[x.split('/')[1]]) {
            mycoThreeData[x.split('/')[1]] = {
                repetitions: [],
                mycobiota_type: 3
            };
        }
        if (x?.split('/')[2]) {
            mycoThreeData[x.split('/')[1]].repetitions[parseInt(x?.split('/')[2], 10)] = values[x];
        }
    });

    return {
        owner: values?.owner,
        sample_date: values?.sample_date?.format('YYYY-MM-DDThh:mm'),
        test_date: values?.test_date?.format('YYYY-MM-DDThh:mm'),
        num: values?.num,
        vega_key: values?.vega_key,
        field_num: values?.field_num,
        year: moment(values?.year).format('YYYY'),
        sample: values?.sample || 'Soil',
        measure: '1000 KOE/gr',
        comment3: values?.comment3,
        comment: values?.comment,
        picture_desc: values?.picture_desc,
        pictures1: values?.pictures1,
        pictures2: values?.pictures2,
        pictures3: values?.pictures3,
        comment2: values?.comment2,
        picture_desc2: values?.picture_desc2,
        culture: values?.culture,
        azotobacter_repetitions: azotobacterRepetitions[0] ? azotobacterRepetitions : undefined,
        microorganisms: [
            ...Object.keys(mycoOneData).map(mycoKey => {
                return {
                    mycobiota_type: mycoOneData[mycoKey].mycobiota_type,
                    repetitions: mycoOneData[mycoKey].repetitions,
                    genius: mycoKey
                };
            }),
            ...Object.keys(mycoTwoData).map(mycoKey => {
                return {
                    mycobiota_type: mycoTwoData[mycoKey].mycobiota_type,
                    repetitions: mycoTwoData[mycoKey].repetitions,
                    genius: mycoKey
                };
            }),
            ...Object.keys(mycoThreeData).map(mycoKey => {
                return {
                    mycobiota_type: mycoThreeData[mycoKey].mycobiota_type,
                    repetitions: mycoThreeData[mycoKey].repetitions,
                    genius: mycoKey
                };
            }),
            {
                repetitions: [...bacteriaRepetitions],
                genius: 40
            }
        ].filter(x => x.repetitions[0]),
        bipolaris: {
            value: values?.bipolaris
        },
        pythium: {
            value: values?.pythium
        },
        alternaria: {
            value: values?.alternaria
        },
        cladosporium: {
            value: values?.cladosporium
        },
        blumeria_graminis: {
            value: values?.blumeria_graminis
        },
        fusarium: {
            value: values?.fusarium
        },
        phoma: {
            value: values?.phoma
        },
        murogenella: {
            value: values?.murogenella
        }
    };
};

export const makeResponse = (registry) => {
    const azotobacterReps = registry?.azotobacter_repetitions;
    const repetitions = registry?.microorganisms[0].repetitions.length;

    const mycobiota1 = registry?.microorganisms.filter(item => item?.mycobiota_type?.id === 1);
    const mycobiota2 = registry?.microorganisms.filter(item => item?.mycobiota_type?.id === 2);
    const mycobiota3 = registry?.microorganisms.filter(item => item?.mycobiota_type?.id === 3);

    const bacteriaMicroorganisms = registry?.microorganisms?.filter(item => item?.genius?.id === 40)[0];

    const data = {
        num: registry.num,
        owner: registry.owner,
        sample_date: moment(registry.sample_date),
        test_date: moment(registry.test_date),
        field_num: registry.field_num,
        comment: registry.comment,
        comment3: registry.comment3,
        pictures1: registry.pictures1,
        pictures2: registry.pictures2,
        pictures3: registry.pictures3,
        // picture_desc: registry.picture_desc,
        comment2: registry.comment2,
        // picture_desc2: registry.picture_desc2,
        repetitions_azotobacter: azotobacterReps?.length,
        year: moment({
            year: registry.year,
            month: 0,
            day: 0,
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
        }),
        culture: registry.culture,
        repetitions,
        bipolaris: registry.bipolaris.value,
        pythium: registry.pythium.value,
        alternaria: registry.alternaria.value,
        cladosporium: registry.cladosporium.value,
        blumeria_graminis: registry.blumeria_graminis.value,
        fusarium: registry.fusarium.value,
        phoma: registry.phoma.value,
        murogenella: registry.murogenella.value
    };

    registry?.pictures1?.forEach(item => {
        data[`picture_desc/1/${item.id}`] = item.picture_desc;
    });
    registry?.pictures2?.forEach(item => {
        data[`picture_desc/2/${item.id}`] = item.picture_desc;
    });
    registry?.pictures3?.forEach(item => {
        data[`picture_desc/3/${item.id}`] = item.picture_desc;
    });

    azotobacterReps?.forEach((itm, idx) => {
        data[`azotobacter/${idx}`] = itm;
    });

    bacteriaMicroorganisms?.repetitions?.forEach((repVal, idx) => {
        data[`mycobiota_type_0/40/${idx}`] = repVal;
    });

    if (mycobiota1?.length > 0) {
        mycobiota1.forEach(x => {
            const mycoRepetitions = x.repetitions;
            mycoRepetitions.forEach((item, index) => {
                data[`mycobiota_type_1/${x.genius?.id}/${index}`] = item;
            });
        });
        // data.mycobiota_type_1 = mycobiota1?.map(x => {
        //     return {
        //         value: x.genius?.id
        //     };
        // });
        data.mycobiota_type_1 = mycobiota1;
    }
    if (mycobiota2?.length > 0) {
        mycobiota2.forEach(x => {
            const mycoRepetitions = x.repetitions;
            mycoRepetitions.forEach((item, index) => {
                data[`mycobiota_type_2/${x.genius?.id}/${index}`] = item;
            });
        });
        // data.mycobiota_type_2 = mycobiota2?.map(x => {
        //     return {
        //         value: x.genius?.id
        //     };
        // });
        data.mycobiota_type_2 = mycobiota2;
    }
    if (mycobiota3?.length > 0) {
        mycobiota3.forEach(x => {
            const mycoRepetitions = x.repetitions;
            mycoRepetitions.forEach((item, index) => {
                data[`mycobiota_type_3/${x.genius?.id}/${index}`] = item;
            });
        });
        // data.mycobiota_type_3 = mycobiota3?.map(x => {
        //     return {
        //         value: x.genius?.id
        //     };
        // });
        data.mycobiota_type_3 = mycobiota3;
    }

    return data;
};