import React from 'react';
import Private from '../../containers/private-route';
import Page from './component';

const Routes = [
    <Private
        exact
        key="meteodata"
        path="/meteodata"
        component={<></>} />
    // <Private
    //     exact
    //     key="meteodata"
    //     path="/meteodata"
    //     component={Page} />
];

export default Routes;